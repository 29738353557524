<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
            <selectComp
              :hval="status"
              :data="auditStatusList"
              @change="statusChange"
              placeholder="状态"
            />
            <selectComp
              placeholder="合作类型"
              :data="cooperationModeList"
              :hval="cooperationMode"
              @change="cooperationModeChange"
            />
            <selectComp
              label="开票类型:"
              prop="billingType"
              placeholder="开票类型"
              :data="billingTypeList"
              :hval="billingType"
              @change="billingTypeChange"
            />
            <selectComp
              label="结算周期:"
              prop="settlementCycle"
              placeholder="结算周期"
              :data="settlementCycleList"
              :hval="settlementCycle"
              @change="settlementCycleChange"
            />
            <inputComp
              placeholder="服务站名称"
              :hval="serviceStationName"
              @blur="serviceStationNameBlur"
            />
            <dateComp
              :hval="date"
              startPlaceholder="验收开始日期"
              endPlaceholder="验收结束日期"
              @change="dateChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchHandle(2)"
              v-permission="'批量审核'"
              >批量审核</btnComp
            >
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchHandle(3)"
              v-permission="'批量结算'"
              >批量结算</btnComp
            >
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @select="select"
          isShowCheckBox
          @selectAll="selectAll"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="postSaleOrderCode" label="工单编号">
            <template v-slot="scope">
              <div style="color:#385BFF;cursor:pointer" @click="codeClick(scope.row)">
                 {{scope.row.postSaleOrderCode}}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column slot="checkTime" label="验收时间" width="180">
            <template v-slot="scope">
              {{ handleDate(scope.row.checkTime) }}
            </template>
          </el-table-column> -->
          <el-table-column slot="time" label="到场时间/验收时间" width="170">
            <template v-slot="scope">
              <div>{{scope.row.arrivalTime?scope.row.arrivalTime:'-'}}</div>
              <div>{{scope.row.checkTime?scope.row.checkTime:'-'}}</div>
              <div></div>
            </template>
          </el-table-column>
          <el-table-column slot="examineTime" label="审核日期" width="180">
            <template v-slot="scope">
              {{ handleDate(scope.row.examineTime) }}
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
     <!-- 详情弹窗 -->
    <drawerDetail
      @close="close"
    />
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>{{ `确定批量${title}吗？` }}</p>
    </dialogComp>
  </div>
</template>

<script>
import drawerDetail from '@/views/work-order/all-order/detail'
import { statOrderAuditList, statOrderAuditUpdateStatus } from '@/api'
import { formatDate } from '@/utils/common'
import constList from '@/mixin/constList'
import { mapMutations } from 'vuex'
export default {
  components: {
    drawerDetail
  },
  mixins: [constList],
  data () {
    return {
      loading: false,
      hintShow: false,
      selectIds: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      title: '',
      code: '',
      status: '',
      cooperationMode: '',
      billingType: '',
      settlementCycle: '',
      serviceStationName: '',
      date: [],
      tableData: [],
      theadName: [
        { prop: 'payStatusName', label: '支付状态' },
        { prop: 'statusName', label: '状态' },
        { slot: 'postSaleOrderCode' },
        { prop: 'serviceStationName', label: '服务站' },
        { prop: 'cooperationModeName', label: '合作类型' },
        { prop: 'provinceCityRegion', label: '区域', width: '200' },
        { prop: 'billingTypeName', label: '开票类型' },
        { prop: 'settlementCycleName', label: '结算周期' },
        { prop: 'price', label: '工单金额' },
        { slot: 'time' },
        { slot: 'examineTime' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        code: this.code,
        status: this.status,
        cooperationMode: this.cooperationMode,
        billingType: this.billingType,
        settlementCycle: this.settlementCycle,
        serviceStationName: this.serviceStationName,
        startCheckTime: this.startCheckTime,
        endCheckTime: this.endCheckTime
      }
      this.loading = true
      statOrderAuditList(params).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 点击工单号弹窗
    codeClick (info) {
      const { orderId, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id: orderId, categoryId, orderCode })
    },
    // 处理时间
    handleDate (date) {
      return date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '-'
    },
    codeBlur (val) {
      this.code = val
    },
    statusChange (val) {
      this.status = val
    },
    cooperationModeChange (val) {
      this.cooperationMode = val
    },
    billingTypeChange (val) {
      this.billingType = val
    },
    settlementCycleChange (val) {
      this.settlementCycle = val
    },
    serviceStationNameBlur (val) {
      this.serviceStationName = val
    },
    dateChange (startCheckTime, endCheckTime) {
      this.startCheckTime = startCheckTime
      this.endCheckTime = endCheckTime
    },
    batchHandle (batchStatus) {
      this.title = batchStatus === 2 ? '审核' : '结算'
      this.batchStatus = batchStatus
      this.hintShow = true
    },
    hintSure () {
      const params = {
        idList: this.selectIds,
        status: this.batchStatus
      }
      statOrderAuditUpdateStatus(params).then(res => {
        if (res.code === '1') {
          this.$message.success(`${this.title}成功`)
          this.close()
        }
      })
    },
    close () {
      this.selectIds = []
      this.hintShow = false
      this.SET_DRAWER_SHOW(false)
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.code = ''
      this.status = ''
      this.cooperationMode = ''
      this.billingType = ''
      this.settlementCycle = ''
      this.serviceStationName = ''
      this.startCheckTime = ''
      this.endCheckTime = ''
      this.date = []
      this.getList()
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
